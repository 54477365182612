export const environment = Object.freeze({
  production: true,

  legacyFrontUrl: 'https://backoffice.bt.fit/#',

  services: {
    baseUrl: 'https://services.bt.fit',
  },

  /** Used to access medias through cloudfront, as images, audios, videos, etc. */
  mediaUrl: 'https://imagesfitness.btfit.com.br/',
});
